import React, {Component} from 'react';
import Slider from 'react-slick';
import SliderArrow from './../assets/icons/SliderArrow';
import SliderPeaceImg from './../assets/images/slider-price-backgroundx2.png'
//Vendors
import "./../../node_modules/slick-carousel/slick/slick.scss";
import Connection from './../connection.json'
import PriceFormatter from './../formatter.js'


function NextArrow(props) {
    const { onClick } = props;
    return (
        <span className='c-hero-slider__arrow c-hero-slider__arrow--next' onClick={onClick}><SliderArrow iconWidth={'20px'} iconHeight={'14px'}/></span>
    );
  }

  function PrevArrow(props) {
    const { onClick } = props;
    return (
        <span className='c-hero-slider__arrow c-hero-slider__arrow--prev' onClick={onClick}><SliderArrow iconWidth={'20px'} iconHeight={'14px'}/></span>
    );
  }


class HeroSlider extends Component {
    constructor() {
      super();
      this.state = {
        sliderItems: []
      }

      this.getItems();
    }

    getItems() {
      fetch(Connection.proxyurl+Connection.sliderItemsUrl)
          .then(response => response.json())
          .then(data => this.setState({sliderItems: data}))
          .catch(err=>console.log(err))
    }

    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            autoplay: true
        };
        return (
          <div className='c-hero-slider'>
            
            <Slider {...settings}>

              {this.state.sliderItems.map( (item, index)=> {
                return(
                  <div className="c-hero-slider__item"
                       key={item.id}>
                       <img title={item.desctiption} src={item.imageUrl}></img>
                    <div className="c-hero-slider__content" style={{background: `#EC2028 url(${SliderPeaceImg}) no-repeat right bottom`}}>
                      <h2 className="u-margin-bottom-tiny u-color-white">{item.name}</h2>
                      {item.desctiption ? <p className='c-hero-slider__description'>{item.desctiption}</p>: null}
                      <p className="c-hero-slider__price">{PriceFormatter(item.price)}<span className="u-font-size-normal u-font-weight-medium">RSD</span></p>
                    </div>
                  </div>
                )
              })}
              
            </Slider>
          </div>
        );
      }
    
}

export default HeroSlider;
